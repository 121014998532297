import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useMetrics } from "../../page-components/projects/metrics"
import {
  LocationTemplateProps,
  Template,
} from "../../page-components/projects/template"
import { imgFinder } from "../../page-components/projects/5-floor-plan"
import ProjectDetails from "../../images/projects/Alsea_Avilion-Gardens_Altiplano.yml"

function amenImg(path: string) {
  return require("../../images/projects/altiplano/06 AMENITIES _ FEATURES/" +
    path)
}

const Altiplano = () => {
  const { logo, siteDevImg, floorPlans } = useStaticQuery(graphql`
    query($floorPlan: String = "/images/projects/altiplano/04 FLOOR PLANS/") {
      logo: file(relativePath: { eq: "altiplano-logo.png" }) {
        ...ImgL
      }
      siteDevImg: file(
        relativePath: { eq: "projects/altiplano/ALTIPLANO SDP.png" }
      ) {
        ...SiteDevImg
      }
      ...FloorPlans
    }
  `)
  const fpImg = imgFinder(floorPlans)
  const metrics = useMetrics([
    { metric: "Buildings", num: "2" },
    { metric: "Storeys", num: "5" },
    { metric: "BedTypesOfUnits", num: "4" },
    { metric: "UnitsperBuilding", num: "122" },
  ])
  const props: LocationTemplateProps = {
    title: "Altiplano",
    logo,
    logoSz: 120,
    location:
      "GUATEMALA ST., BETTER LIVING SUBDIVISION, BRGY. DON BOSCO, PARANAQUE CITY",
    metrics,
    carouselImgs: [
      require("../../images/projects/altiplano/01 ALTIPLANO.jpg"),
      require("../../images/projects/altiplano/02 ALTIPLANO.jpg"),
      require("../../images/projects/altiplano/03 ALTIPLANO.jpg"),
      require("../../images/projects/altiplano/04 ALTIPLANO.jpg"),
    ],
    siteDev: {
      img: siteDevImg,
      txt:
        "Sitting inside the gated community of Avilion Gardens, Altiplano consists of two identical condominium buildings parallel to one another. Surrounded by luscious greens and fresh air, Altiplano is a fully equipped community with its own clubhouse, chapel, gym, swimming pool, and playground.",
    },
    amenities: {
      description:
        "Enjoy the various shared amenities within the community, fully equipped with a swimming pool & playground, and a clubhouse that houses a prayer room, fitness gym, and function room - all within reach and easy access. Each Altiplano building also enjoys its own private facilities and commercial area. ",
      items: [
        {
          img: require("../../images/projects/altiplano/06 AMENITIES _ FEATURES/01 ALTIPLANO LOBBY.png"),
          name: "Building Lobby",
          description: [
            "State-of-the-art Lobby",
            "Commercial Area",
            "High-speed Elevator",
            "CCTV in Common Areas",
            "100% standby Emergency Power for Common Areas",
            "Automatic Fire Alarm, Fire Detector, and Sprinkler System",
            "Electrified Perimeter Fence",
            "Basement Parking",
            "Mail Area",
            "Garbage Area",
          ],
        },
        {
          img: require("../../images/projects/altiplano/06 AMENITIES _ FEATURES/02 ATLIPLANO CLUBHOUSE PERSP.png"),
          name: "Clubhouse Exterior",
          description:
            "The Avilion Gardens Clubhouse is ready for anyone's use with its function room, chapel, and gym. The clubhouse is surrounded by other outdoor amenities such as the swimming pool and the playground.",
        },
        {
          img: require("../../images/projects/altiplano/06 AMENITIES _ FEATURES/03 ALTIPLANO PLAYGROUND.png"),
          name: "Playground",
          description:
            "Discover endless fun with your family at the playground. ",
        },
        {
          img: require("../../images/projects/altiplano/06 AMENITIES _ FEATURES/04 ALTIPLANO SWIMMING POOL.png"),
          name: "Swimming Pool",
          description:
            "Enjoy the sun and have a relaxing time by the pool area. ",
        },
        {
          img: require("../../images/projects/altiplano/06 AMENITIES _ FEATURES/05 ALTIPLANO CHAPEL.png"),
          name: "Chapel",
          description:
            "Find inner peace in the prayer room wherein you can set your intentions in a safe and sacred space. ",
        },
        {
          img: require("../../images/projects/altiplano/06 AMENITIES _ FEATURES/06 ALTIPLANO GYM.png"),
          name: "Gym",
          description:
            "Gain physical wellness and build up your strength in the gym fully equipped with the essentials.",
        },
      ],
    },
    floorPlans: {
      description:
        "Altiplano has two (2) identical buildings, each with five (5) floors each, while offering four (4) unit types to cater to various users. All units are equipped with its own private garden/balcony to fully enjoy the space surrounding them. ** All Floor Plans below are for suggested layout only.",
      items: [
        [
          { name: "The Lobby", fpImg: fpImg("01 FP THE LOBBY") },
          { name: "First Floor", fpImg: fpImg("02 FP FIRST FLR") },
          { name: "Second Floor", fpImg: fpImg("03 FP SECOND FLR") },
          { name: "Third Floor", fpImg: fpImg("04 FP THIRD FLR") },
          { name: "The Penthouse", fpImg: fpImg("05 FP THE PENTHOUSE") },
          { name: "The Basement", fpImg: fpImg("06 FP THE BASEMENT") },
        ],
        [
          {
            name: "Studio A",
            fpImg: fpImg("01 UNIT STUDIOA FP"),
            pImg: fpImg("01 UNIT STUDIOA PERSP"),
          },
          {
            name: "Studio B",
            fpImg: fpImg("02 UNIT STUDIOB FP"),
            pImg: fpImg("02 UNIT STUDIOB PERSP"),
          },
          {
            name: "1 Bedroom",
            fpImg: fpImg("03 UNIT 1BR FP"),
            pImg: fpImg("03 UNIT 1BR PERSP"),
          },
          {
            name: "4 Bedroom",
            fpImg: fpImg("04 UNIT 4BR FP"),
            pImg: fpImg("04 UNIT 4BR PERSP"),
          },
          { name: "Commercial A/B", fpImg: fpImg("05 UNIT COMMERCIAL FP") },
        ],
      ],
    },
    projDetails: {
      ...ProjectDetails,
      name: "Altiplano",
      description: `First Shelter takes you to even greater heights with the Altiplano at Avilion Gardens.

      Strategically located in the heart of bustling Parañaque, the Altiplano condominiums will be nestled within Avilion Gardens’ exclusive one-hectare townhouse development.

      It has two 5-storey buildings which will be home to a variety of spaces, suiting every modern filipino’s lifestyle. With luxuries such as state-of-the art amenities and commercial establishments, prepare yourself to embark on a life of ultimate comfort and sophistication.

      With four unit types, each building houses a total of 122 units.`,
    },
  }
  return <Template {...props} />
}

export default Altiplano
